// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background:rgba(0, 0, 0, .2);//hsla(9, 100%, 64%, 0.4);//
            @media screen and (max-width: 1400px){
                bottom: 310px;
                @media screen and (max-width: $size-xs-max) {
                    bottom: 490px;
                    @media screen and (max-width: 375px) {
                        bottom: 545px;
                    }
                }
            }
        }
        &_caption {
            position: absolute;
            top: 55%;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
            z-index: $z-index-slide-caption;
            display: flex !important;
            padding: 0 5%;
            .left-side {
                h2 {
                    font-weight: bold;
                    font-size: $font-size-60;
                    color: $color-2;
                    padding-bottom: 25px;
                }
                a.btn-slideshow {
                    background: $color-1;
                    width: 290px;
                    height: 60px;
                    margin-top: 25px;
                    border-radius: 5px;
                    transition: 0.3s;
                    h5 {
                        font-weight: 500;
                        font-size: $font-size-20;
                        text-transform: uppercase;
                        color: $color-2;
                        transition: 0.3s;
                    }
                    &:hover {
                        background: $color-2;
                        transition: 0.3s;
                        h5 {
                            font-weight: normal;
                            color: $color-1;
                            transition: 0.3s;
                        }
                    }
                }
            }
            @media screen and (max-width: 1400px){
                top: 37%;
                @media screen and (max-width: 1200px){
                    top: 33%;
                    @media screen and (max-width: $size-nav-toggle-breakpoint) {
                        top: 29%;
                        @media screen and (max-width: $size-sm-max) {
                            top: 24%;
                            .left-side {
                                h2 {
                                    font-size: 2.8rem;
                                    padding-bottom: 0;
                                }
                            }
                            @media screen and (max-width: $size-xs-max) {
                                top: 32%;
                                .left-side {
                                    h2 br {
                                        display: none;
                                    }
                                    .section-buttons {
                                        flex-direction: column;
                                        a.btn-slideshow {
                                            width: 235px;
                                        }
                                        a.btn-slideshow.mr6 {
                                            margin: 25px 0 0 0;
                                        }
                                    }
                                }
                                @media screen and (max-width: 375px) {
                                    top: 27%;
                                    @media screen and (max-width: 360px) {
                                        top: 22%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .section-text {
            position: absolute;
            right: 5%;
            background: rgba(0, 114, 52, 0.6);
            backdrop-filter: blur(35px);
            width: 40%;
            padding: 35px 50px;
            bottom: -38px;
            p {
                color: $color-2;
            }
            @media screen and (max-width: 1600px){
                bottom: -150px;
                @media screen and (max-width: 1400px){
                    position: initial;
                    width: 100%;
                    padding: 75px 5%;
                    background: $color-1;
                    backdrop-filter: none;
                    margin-top: -3px;
                }
            }
        }
    }
}

#section-image-text {
    padding: 50px 0 50px 5%;
    margin: 100px 0 0 0;
    position: relative;
    .text-side {
        width: 40%;
        margin-right: 5%;
        h2 {
            font-weight: bold;
            font-size: $font-size-70;
            color: $color-1;
            text-transform: uppercase;
            padding-bottom: 35px;
        }
        a.btn-plus {
            background: $color-1;
            width: 240px;
            height: 65px;
            margin-top: 65px;
            .icon {
                img {
                  transition: 0.5s;
                }
            }
            h5 {
                font-weight: bold;
                font-size: $font-size-14;
                color: #F0EFE7;
                text-transform: uppercase;
                line-height: 1.5;
            }
            &:hover {
                img {
                    transform: rotate(180deg);
                    transition: 0.5s;
                }
            }
        }
    }
    .image-side {
        width: 60%;
    }
    &::before {
        content: '';
        background: #F0EFE780;
        z-index: -1;
        width: 80%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        margin-right: 5%;
    }
    @media screen and (max-width: 1600px) {
        margin: 200px 0 0 0;
        @media screen and (max-width: 1400px){
            margin: 75px 0 0 0;
            @media screen and (max-width: 1200px){
                flex-direction: column;
                padding: 75px 5%;
                margin: 0;
                .text-side {
                    width: 100%;
                    margin: 0 0 50px 0;
                    a.btn-plus {
                        margin-top: 45px;
                    }
                }
                .image-side {
                    width: 80%;
                }
                &::before {
                    width: 100%;
                }
                @media screen and (max-width: $size-sm-max) {
                    .image-side {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .text-side {
                            h2 {
                                font-size: 3.2rem;
                            }
                            a.btn-plus {
                                width: 220px;
                                h5 {
                                    font-size: 0.9rem;
                                }
                            }
                        }
                        @media screen and (max-width: 375px) {
                            .text-side {
                                h2 {
                                    font-size: 2.9rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-logo {
    padding: 75px 5%;
    @media screen and (max-width: $size-xs-max) {
        flex-wrap: wrap;
        .logo {
            width: 50%;
            padding: 15px;
        }
        .logo.mr8 {
            margin: 0;
        }
    }
}

/*******************************************************************************
* QUI NOUS SOMMES
*******************************************************************************/

#section-image-text.sommes {
    margin: 35px 0 0 0;
    .text-side {
        h2 {
            padding-bottom: 0;
        }
        .section-text {
            margin-top: 150px;
            p {
                span {
                    font-weight: bold;
                }
            }
            a.btn-plus {
                width: 225px;
                height: 50px;
            }
        }
    }
    &::before {
        content: '';
        background: #F0EFE780;
        z-index: -1;
        width: 80%;
        height: 67%;
        position: absolute;
        left: 0;
        bottom: 0;
        top: initial;
        margin-right: 5%;
    }
    @media screen and (max-width: 1780px) {
        .text-side h2 {
            font-size: 3.2rem;
        }
        @media screen and (max-width: 1630px) {
            background: #F0EFE780;
            .text-side {
                h2 {
                    font-size: 3rem;
                }
                .section-text {
                    margin-top: 25px;
                }
            }
            &::before {
                display: none;

            }
            @media screen and (max-width: 1530px) {
                .text-side {
                    width: 45%;
                }
                @media screen and (max-width: 1200px) {
                    .text-side {
                        width: 100%;
                    }
                }
            }
        }
    }
}

#section-title-btn {
    padding: 100px 5% 0 5%;
    align-items: flex-end;
    .title {
        width: 75%;
        h3 {
            font-weight: bold;
            font-size: $font-size-48;
            color: $color-1;
            text-transform: initial;
        }
    }
    a.btn-plus {
        background: $color-1;
        width: 240px;
        height: 50px;
        .icon {
            img {
                transition: 0.5s;
            }
        }
        h5 {
            font-weight: bold;
            font-size: $font-size-14;
            color: #F0EFE7;
            text-transform: uppercase;
            line-height: 1.5;
        }
        &:hover {
            img {
                transform: rotate(180deg);
                transition: 0.5s;
            }
        }
    }
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        align-items: initial;
        .title {
            width: 100%;
            margin-bottom: 35px;
        }
        @media screen and (max-width: $size-xs-max) {
            .title {
                h3 br {
                    display: none;
                }
            }
            a.btn-plus {
                width: 200px;
            }
        }
    }
}

#hero.contenu {
    padding: 50px 5%;
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-title {
    margin-top: 250px;
    padding: 0 5% 35px 5%;
    h3 {
        font-weight: bold;
        font-size: $font-size-60;
        color: #1A1818;
    }
    @media screen and (max-width: 1145px) {
        h3 br {
            display: none;
        }
        @media screen and (max-width: 1024px) {
            margin-top: 200px;
            @media screen and (max-width: $size-sm-max) {
                margin-top: 225px;
                @media screen and (max-width: $size-xs-max) {
                    margin-top: 200px;
                    h3 {
                        font-size: 2.8rem;
                    }
                }
            }
        }
    }
}

#section-contenu-img {
    padding: 50px 5% 0 5%;
    .left-side {
        width: 55%;
        margin-right: 15px;
        background: $color-2;
        padding: 50px;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-1;
            padding-bottom: 25px;
        }
        h3 {
            font-weight: bold;
            font-size: $font-size-48;
            color: $color-1;
            text-transform: initial;
            padding-bottom: 25px;
        }
        a.btn-plus {
            background: $color-1;
            width: 240px;
            height: 65px;
            margin-top: 35px;
            .icon {
                img {
                    transition: 0.5s;
                }
            }
            h5 {
                font-weight: bold;
                font-size: $font-size-14;
                color: #F0EFE7;
                text-transform: uppercase;
                line-height: 1.5;
            }
            &:hover {
                img {
                    transform: rotate(180deg);
                    transition: 0.5s;
                }
            }
        }
        &.color2 {
            background: #F0EFE780;
        }
    }
    @media screen and (max-width: 1550px) {
        .left-side {
            h4, h3 {
                br {
                    display: none;
                }
            }
        } 
        @media screen and (max-width: 1430px) {
            .left-side {
                padding: 35px 50px;
                h3 {
                    font-size: 2rem;
                }
            } 
            @media screen and (max-width: 1335px) {
                .left-side {
                    padding: 35px;
                } 
                @media screen and (max-width: 1215px) {
                    flex-direction: column;
                    .left-side {
                        padding: 50px 5%;
                        width: 100%;
                        margin: 0 0 15px 0;
                        a.btn-plus h5 {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}

#section-img-contenu {
    padding: 35px 5% 0 5%;
    .right-side {
        width: 55%;
        margin-left: 15px;
        background: #DEEFE13C;
        padding: 50px;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-1;
            padding-bottom: 25px;
        }
        h3 {
            font-weight: bold;
            font-size: $font-size-48;
            color: $color-1;
            text-transform: initial;
            padding-bottom: 25px;
        }
        a.btn-plus {
            background: $color-1;
            width: 240px;
            height: 50px;
            margin-top: 50px;
            .icon {
                img {
                    transition: 0.5s;
                }
            }
            h5 {
                font-weight: bold;
                font-size: $font-size-14;
                color: #F0EFE7;
                text-transform: uppercase;
                line-height: 1.5;
            }
            &:hover {
                img {
                    transform: rotate(180deg);
                    transition: 0.5s;
                }
            }
        }
    }
    @media screen and (max-width: 1758px) {
        .right-side {
            h3 {
                br {
                    display: none;
                }
            }
        } 
        @media screen and (max-width: 1430px) {
            .right-side {
                padding: 35px 50px;
                h3 {
                    font-size: 2rem;
                }
            }
            @media screen and (max-width: 1215px) {
                flex-direction: column-reverse;
                .right-side {
                    padding: 50px 5%;
                    width: 100%;
                    margin: 0 0 15px 0;
                    a.btn-plus h5 {
                        font-size: 1rem;
                    }
                }
            }
        }
    } 
}

#section-text {
    padding: 100px 5%;
    background: #F0EFE780;
    text-align: center;
    h2 {
        font-weight: bold;
        font-size: $font-size-60;
        color: $color-1;
    }
    @media screen and (max-width: 1660px) {
        h2 br {
            display: none;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 75px 5%;
            h2 {
                font-size: 27px;
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 35px 5% 75px 5%;
    h4 {
        font-weight: normal;
        font-size: $font-size-32;
        color: #1A1818;
        padding-bottom: 35px;
        text-align: center;
        line-height: 1.3;
        @media screen and (max-width: $size-xs-max){
            br {
                display: none;
            }
        }
    }
    .realisations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);     
            }
        }
    }
    #blocIntro_promotions {
        h3 {
            color: $color-1;
            line-height: 1.3;
        }
    }
}

/*******************************************************************************
* CARRIÈRE
*******************************************************************************/

#section-info {
    padding: 50px 0;
    border-bottom: 1px solid #000;
    margin: 0 5%;
    .left-side {
        width: 50%;
        margin-right: 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #1A1818;
        }
    }
    .right-side {
        width: 50%;
        p {
            font-size: $font-size-26;
            color: #1A1818;
        }
    }
    @media screen and (max-width: 1100px){
        flex-direction: column;
        .left-side {
            width: 100%;
            margin: 0 0 35px 0;
        }
        .right-side {
            width: 100%;
        }
    }
}

#section-emploi {
    padding: 75px 5%;
    div.section-wrapper {
        display: inline-grid;
        margin: auto;
        width: 90%;
        max-width: 1600px;;
        justify-items: stretch;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        @media screen and (max-width: $size-sm-max){
            grid-template-columns: 1fr;
        }
    }
    div.left-side, div.right-side {
        width: 50%;
        @media screen and (max-width: $size-xs-max){
          width: 100%;
        }
    }

    section.emplois-liste {
        display: flex;
        flex-wrap: wrap;
        div.item-poste {
            display: inline-block;
            margin-bottom: 60px;
            background-color: $color-2;
            transition: .5s;
            width: 49%;

            div.title {
                background-color: $color-1;
                padding: 30px 7%;
                h4 {
                    color: #DEEFE1;
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: initial;
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.soustitre {
                padding: 30px 7%;
                .icon {
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $color-1;
                    margin-right: 50px;
                    .fa {
                        font-size: 20px;
                        margin-right: 10px;
                    }
                    @media screen and (max-width: 1420px){
                        margin-right: 25px;
                        @media screen and (max-width: 1270px){
                            margin-right: 0;
                            width: 100%;
                            margin-bottom: 15px;
                            @media screen and (max-width: 1100px){
                                margin-right: 25px;
                                width: initial;
                                margin-bottom: 0;
                                @media screen and (max-width: $size-xs-max){
                                    margin-right: 0;
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.content-full {
                padding: 0 7% 30px 7%;
                h4, h3, h2 {
                    font-size: 18px;
                    color: $color-1;
                    padding: 15px 0;
                    font-weight: bold;
                }
                p, li {
                    color: $color-1;
                    font-weight: normal;
                    font-size: 16px;
                    padding-left: 25px;
                    @media screen and (max-width: 1420px){
                        padding-left: 0;
                    }
                }
                .btn-wrapper {
                    max-width: 200px;
                    margin-top: 50px;
                    .btn {
                        padding: 20px 5px;
                        background-color: $color-1;
                        &:hover {
                            border: none;
                            color: $color-2;
                            opacity: 0.7;
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 0 5% 30px 5%;
                }
            }
            &:nth-child(odd) {
                margin-right: 15px;
                @media screen and (max-width: 1100px){
                    margin-right: 0;
                }
            }
            @media screen and (max-width: 1100px){
                width: 100%;
                margin: 0 0 15px 0;
            }
        }
    }
}

section.section-form-emploi {
    padding: 50px 8%;
    background-color: #DEEFE13C;
    h3 {
        font-weight: bold;
        color: #3D4251;
        font-size: $font-size-30;
        padding-bottom: 15px;
        @media screen and (max-width: 360px) {
            font-size: 1.4rem;
        }
    }
    form label.filedoc {
        background: $color-1;
        width: 230px;
        color: $color-2;
    }
    .btn {
        border-radius: 0;
        &:hover {
            border: none;
            background: $color-1;
            opacity: 0.7;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .pl0 , .pr0, {
            padding: 0!important;
        }
        form label.filedoc {
            width: 230px;
        }
        form .buttons {
            justify-content: center;
        }
        .btn-submit {
            margin-top: 15px;
            .btn.submit-emploi {
                height: 45px;
            }
        }
    }
    @media screen and (max-width: 1325px){
        padding: 30px 5%;
    }
}

/*******************************************************************************
* NOUS JOINDRE
*******************************************************************************/

#section-coordonnees {
    padding: 0 5% 100px 5%;
    background: #CACBD53C;
    .form-info.flex {
        position: relative;
        margin-top: -250px;
    }
    .section-form {
        width: 65%;
        margin-right: 25px;
        background: $color-white;
        padding: 65px 100px 50px 100px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #3D4251;
            padding-bottom: 10px;
        }
        p {
            padding-bottom: 35px;
            @media screen and (max-width: $size-xs-max) {
                text-align: center;
            }   
        }
        .form-group {
            margin-bottom: -15px;
        }
    }
    .text-side {
        width: 35%;
        padding: 65px 45px 35px 45px;
        background: $color-1;
        h3 {
            font-weight: 500;
            font-size: $font-size-28;
            color: $color-2;
            padding-bottom: 50px;
            @media screen and (max-width: 1820px) {
                br {
                    display: none;
                }
            }
        }
        .section-info {
            .text {
                width: 100%;
                margin-left: 25px;
                h4 {
                    font-weight: bold;
                    font-size: $font-size-26;
                    color: $color-2;
                    padding-bottom: 10px;
                }
                h5 {
                    font-weight: normal;
                    font-size: $font-size-18;
                    color: $color-2;
                }
                .jours-heures {
                    padding-top: 10px;
                    h4 {
                        font-weight: bold;
                        font-size: $font-size-18;
                        color: $color-2;
                        padding-bottom: 0;
                        line-height: 2;
                    }
                    h5 {
                        line-height: 2;
                    }
                    .jour.mr8 {
                        width: 55%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1515px) {
        .text-side h3 {
            text-align: center;
        }
        @media screen and (max-width: 1425px) {
            .text-side {
                padding: 65px 35px 35px 35px;
            }
            @media screen and (max-width: 1365px) {
                .text-side {
                    .section-info {
                        .text {
                            .jours-heures {
                                .jour.mr8 {
                                    width: 45%;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: $size-md-max) {
                    .form-info {
                        flex-direction: column-reverse;
                        margin-top: -150px !important;
                        .text-side {
                            width: 75%;
                            margin: auto;
                            .section-info {
                                .text {
                                    .jours-heures {
                                        .jour.mr8 {
                                            width: 30%;
                                        }
                                    }
                                }
                            }
                        }
                        .section-form {
                            width: 85%;
                            margin: 35px auto 0 auto;
                        }
                    }
                    @media screen and (max-width: 1024px) {
                        .form-info {
                            .text-side {
                                width: 85%;
                                padding: 65px 45px;
                            }
                            .section-form {
                                width: 100%;
                            }
                        }
                        @media screen and (max-width: $size-sm-max) {
                            .form-info {
                                margin-top: -95px !important;
                                .text-side {
                                    width: 90%;
                                }
                                .section-form {
                                    padding: 65px 50px 50px 50px;
                                }
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .form-info {
                                    margin-top: -130px !important;
                                    .text-side {
                                        width: 100%;
                                        padding: 50px 25px;
                                    }
                                    .section-form {
                                        padding: 50px 25px;
                                        .form-group.col-xs-12.col-sm-6.pl0 {
                                            padding: 0;
                                        }
                                        h3 {
                                            text-align: center;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    padding: 75px 5%;
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 10px;
        color: $color-1;
    }
    p a {
        color: $color-3;
    }
}







/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    

}

@media screen and (max-width: $size-xs-max) {
   

}
